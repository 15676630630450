import { Placement } from '@popperjs/core';
import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon, iconLibrary } from './Icon';

let idCtr = 1;

type Props = React.HTMLProps<HTMLSpanElement> & {
    icon?: 'question' | 'info' | 'exclamation';
    placement?: Placement;
}
export const HelpTooltip: React.FunctionComponent<Props> = ({ children, placement, icon = 'question', ...rest }) => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const id = React.useMemo(() => (idCtr++).toString(), [children]);
    const actualIcon = icon == 'question' ? iconLibrary.faQuestionCircle : icon == 'exclamation' ? iconLibrary.faExclamationCircle : iconLibrary.faInfoCircle;
    return <OverlayTrigger placement={placement} overlay={<Tooltip id={id}><span style={{ width: 325 }}>{children}</span></Tooltip>}><span {...rest}><Icon icon={actualIcon} /></span></OverlayTrigger>;
}