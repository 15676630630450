import { setHahContext } from '@hah/typewriter';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Col, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap';
import useOnclickOutside from 'react-cool-onclickoutside';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
// cannot figure out why its not including typings, maybe because tsconfig includes only src folder? Either way hopefully this will work on prod?
import '../../node_modules/@types/google.maps/index.d.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconLibrary, useOnMountUnsafe } from '@hah/shared';
import { MultiOrderJobPointer } from '@hah/enums';
import * as Sentry from '@sentry/browser';

// Utility type to capitalize the first letter of a string
type CapitalizeFirstLetter<S extends string> = S extends `${infer T}${infer U}` ? `${Uppercase<T>}${U}` : S;

// Utility type to transform properties to have capitalized first letter
type CapitalizedProperties<T> = {
    [K in keyof T as CapitalizeFirstLetter<K & string>]: T[K]
};
// Transformed interface
export type CapitalizedJobDetailsAddressViewModel = CapitalizedProperties<models.JobDetailsAddressViewModel>;

// set hah version header and context
const hahVersion = import.meta.env.VITE_HAHVERSION;
setHahContext('hah-public-react-jobdetails-index', hahVersion);

type Props = {
    jobAddress: models.JobDetailsAddressViewModel;
    isPrimaryAddress: boolean;
    isPrimaryJob: boolean;
};
export const JobDetailsAddressAutoComplete = (options: Props) => {
    const { jobAddress, isPrimaryAddress, isPrimaryJob } = options;
    const [fullAddress, setFullAddress] = useState<models.JobDetailsAddressViewModel>(jobAddress);
    const [loading, setLoading] = useState<boolean>(true);
    const initialLoadOccuring = useRef(true);
    const jobPointer = isPrimaryJob ? MultiOrderJobPointer.PrimaryJob : MultiOrderJobPointer.SecondaryJob;
    let addressPrefix = isPrimaryJob ? 'PrimaryJob_' : 'SecondaryJob_';
    addressPrefix += isPrimaryAddress ? 'PrimaryJobAddress_' : 'SecondaryJobAddress_';

    const handleSetInitialValues = () => {
        // On initial load set the street address but don't query the API
        setValue(jobAddress.street, false);
        setLoading(false);
        initialLoadOccuring.current = false;
    }

    useOnMountUnsafe(() => {
        window.reactLoaded = true;
        if (window.jobDetailsReady) {
            handleSetInitialValues();
        }
        else {
            // If the job details are not ready we need to wait for them to be ready before setting the initial values
            const interval = setInterval(() => {
                if (window.jobDetailsReady) {
                    handleSetInitialValues();
                    clearInterval(interval);
                }
            }, 10);
        }
    });

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions
    } = usePlacesAutocomplete({
        requestOptions: {
            types: ['address'],
            componentRestrictions: { country: 'us' },
        },
        debounce: 300,
    });
    const ref = useOnclickOutside(() => {
        // When the user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });

    const handleSelect = (suggestion: google.maps.places.AutocompletePrediction) => {
        Sentry?.addBreadcrumb({
            category: 'address-autocomplete',
            type: 'info',
            message: 'Selected address from autocomplete dropdown',
        });
        // When the user selects a place, we can replace the keyword without request data from API by setting the second parameter to "false"
        setValue(suggestion.structured_formatting.main_text, false);
        setFullAddress(prev => ({ ...prev, street: suggestion.structured_formatting.main_text }));
        clearSuggestions();

        getGeocode({ placeId: suggestion.place_id }).then((results) => {
            //set values for state, city
            let citySet = false;
            let zipSet = isPrimaryAddress;
            let stateSet = false;
            let neighborhood = '';
            results.forEach((result) => {
                const addressTypes = result.address_components.filter((address) => 
                    address.types.some(type => 
                        ['locality', 'administrative_area_level_3', 'sublocality', 'sublocality_level_1', 'administrative_area_level_1', 'postal_code', 'neighborhood'].includes(type)
                    )
                );
                addressTypes.forEach((address) => {
                    if (!citySet && (
                        address.types.includes('locality') ||
                        address.types.includes('administrative_area_level_3') ||
                        address.types.includes('sublocality_level_1') ||
                        address.types.includes('sublocality')
                    )) {
                        setFullAddress(prev => ({ ...prev, city: address.long_name }));
                        citySet = true;
                        return;
                    }
                    if (!stateSet && address.types.includes('administrative_area_level_1')) {
                        setFullAddress(prev => ({ ...prev, state: address.short_name }));
                        stateSet = true;
                        return;
                    }
                    if (address.types.includes('postal_code')) {
                        // If this ZIP does not match what we have we should warn them? It would be better if we could set the zip but then we'd have to do a lookup for the provider again.
                        if (zipSet && fullAddress.zip !== address.short_name) {
                            globalSitewide.toastError(null, 'The ZIP code for the address you selected does not match what you entered before. If the ZIP you entered is incorrect please go back and ensure you select the correct provider for your ZIP code.');
                            HireahelperCsLogger.Main.sendDataWithMsg('JobDetails Address Autocomplete ZIP Mismatch', { tags: { params: window.location.href, geoAddress: result.formatted_address, 'grouping.Checkout': true } }, 2);
                            return;
                        }
                        setFullAddress(prev => ({ ...prev, zip: address.short_name }));
                        zipSet = true;
                        return;
                    }
                    if (address.types.includes('neighborhood')) {
                        neighborhood = address.long_name;
                        return;
                    }
                });

                // Final fallback - If city is not set, set it to the neighborhood
                if (!citySet && neighborhood.length > 0) {
                    setFullAddress(prev => ({ ...prev, city: neighborhood }));
                    citySet = true;
                }
                // Return early if we've set all the values
                if (citySet && stateSet && zipSet) {
                    return;
                }
                // If we didn't set the city, state, or zip we need to log this so we can investigate and show the user a message that we couldn't set the values
                if (!citySet) {
                    HireahelperCsLogger.Main.sendDataWithMsg('JobDetails Address Autocomplete City Not Found', { tags: { params: window.location.href, geoAddress: result.formatted_address, 'grouping.Checkout': true } }, 3);
                    globalSitewide.toastError(null, 'We couldn\'t find a city for the address you entered, please check the address and try again.');
                }
                if (!stateSet) {
                    HireahelperCsLogger.Main.sendDataWithMsg('JobDetails Address Autocomplete State Not Found', { tags: { params: window.location.href, geoAddress: result.formatted_address, 'grouping.Checkout': true } }, 3);
                    globalSitewide.toastError(null, 'We couldn\'t find a state for the address you entered, please check the address and try again.');
                }
                if (!zipSet) {
                    HireahelperCsLogger.Main.sendDataWithMsg('JobDetails Address Autocomplete ZIP Not Found', { tags: { params: window.location.href, geoAddress: result.formatted_address, 'grouping.Checkout': true } }, 3);
                    globalSitewide.toastError(null, 'We couldn\'t find a ZIP code for the address you entered, please check the address and try again.');
                }
            });
        });
    };

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li className="autocomplete-entry" key={place_id} onClick={() => handleSelect(suggestion)}>
                    <FontAwesomeIcon className="me-2" icon={iconLibrary.faMapMarkerAlt} /> {main_text} {secondary_text}
                </li>
            );
        });

    const handleChangeZip = (event: ChangeEvent<HTMLInputElement>) => {
        // Primary Address ZIP is read only
        if (!isPrimaryAddress) {
            setFullAddress(prev => ({ ...prev, zip: event.target.value }));
        }
    }

    const handleChangeStreet = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        setFullAddress(prev => ({ ...prev, street: event.target.value }));
    }

    const convertAddressKeysToUppercase = (address: models.JobDetailsAddressViewModel): CapitalizedProperties<models.JobDetailsAddressViewModel> => {
        return {
            City: address.city,
            State: address.state,
            Street: address.street,
            StreetLineTwo: address.streetLineTwo,
            Zip: address.zip
        } as CapitalizedJobDetailsAddressViewModel;
    }

    useEffect(() => {
        // Page KO not ready
        if (initialLoadOccuring.current) {
            return;
        }
        // This is silly but our react models are generated lowercase and the ko models are generated with the first letter capitalized
        const addressToSend = convertAddressKeysToUppercase(fullAddress);
        // Set KO field values
        JobDetails.JobDetailsPage.UpdateAddress(addressPrefix, jobPointer, addressToSend);
    }, [addressPrefix, fullAddress, jobPointer]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <div ref={ref}>
            <Row>
                <Col>
                    <FloatingLabel label="Street Address" className="">
                        <Form.Control className={`js-${isPrimaryAddress ? 'primary' : 'secondary'}StreetAddress`} id={`${addressPrefix}Street`} maxLength={500} placeholder="Street Address" value={value} onChange={handleChangeStreet} disabled={!ready} type="text" />
                        {status === 'OK' && <ul className="autocomplete position-absolute z-index-1 bg-white mt-1 list-unstyled">{renderSuggestions()}</ul>}
                    </FloatingLabel>
                </Col>
                <Col md={'auto'}>
                    <FloatingLabel label="Apt/Suite" className="">
                        <Form.Control id={`${addressPrefix}StreetLineTwo`} placeholder="Apt/Suite" value={fullAddress.streetLineTwo} onChange={(v) => setFullAddress(prev => ({ ...prev, streetLineTwo: v.target.value }))} type="text" />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FloatingLabel label="City" className="">
                        <Form.Control id={`${addressPrefix}City`} maxLength={50} placeholder="City" value={fullAddress.city} onChange={(v) => setFullAddress(prev => ({ ...prev, city: v.target.value }))} type="text" />
                    </FloatingLabel>
                </Col>
                <Col md={'auto'}>
                    <FloatingLabel label="State" className="">
                        <Form.Control id={`${addressPrefix}State`} maxLength={2} placeholder="State" value={fullAddress.state} onChange={(v) => setFullAddress(prev => ({ ...prev, state: v.target.value }))} type="text" />
                    </FloatingLabel>
                </Col>
                <Col md={'auto'}>
                    <FloatingLabel label="ZIP" className="">
                        <Form.Control id={`${addressPrefix}Zip`} maxLength={5} placeholder="ZIP" value={fullAddress.zip} disabled={isPrimaryAddress} readOnly={isPrimaryAddress} onChange={handleChangeZip} type="text" />
                    </FloatingLabel>
                </Col>
            </Row>
        </div>
    );
};
