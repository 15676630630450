
import { useOnMountUnsafe } from '@hah/shared';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { setHahContext } from '../custom-types/http-utils';
import { JobList } from './components/JobList';
import { PaymentInfo } from './components/PaymentInfo';
import { PorchHomeAssistant } from './components/PorchHomeAssistant';
import { ReservationDetails } from './components/ReservationDetails';
import { DevOnlyWrapper } from './components/Shared/DevOnlyWrapper';
import { useBookOrderHandler } from './hooks/useBookOrderHandler';
import { ExperimentIds } from '@hah/typewriter/constants';

type Props = {
    pageVm: models.CheckoutPaymentStepReactViewModel;
};

// set hah version header and context
const hahVersion = import.meta.env.VITE_HAHVERSION;
setHahContext('hah-public-react-checkout-index', hahVersion);

export const CheckoutApp = (options: Props) => {
    const { handleSubmit, isFormSubmitting, viewModel, model, handleChange } = useBookOrderHandler(options.pageVm);

    const [showPayNowButtons, setShowPayNowButtons] = useState<boolean>(false);

    const optimizely = window.optimizely;

    const enablePayNow = () => {
        // Default is payNow - set call handleChange to set that UX
        handleChange({ payNow: true });
        setShowPayNowButtons(true);
    };

    useOnMountUnsafe(() => {
        if (!viewModel.payNowEligible) {
            console.info('🧪 index: job is NOT pay now eligible');
            return;
        }

        console.info('🧪 index: eligible for pay now, so calling bucketAndCheckForOptimizelyVariant to see what optimizely says');
        Hireahelper.Global.AbTestingUtils.bucketAndCheckForOptimizelyVariant(optimizely,
            ExperimentIds.TestPMG3851_CheckoutPayNow_ApiName,
            ExperimentIds.TestPMG3851_CheckoutPayNow_ID).then((variant) => {
            if (variant !== ExperimentIds.TestPMG3851_CheckoutPayNow_VariantID) {
                console.info('🧪 index: optimizely did not bucket us for the variant, so doing nothing', { variant });
                return;
            }
            console.info('🧪 index: user is in variation 1: showing pay now / pay later toggle buttons');

            enablePayNow();
        });
    });

    return (
        <div id="react-checkout-container" className="mt-3">
            <Row>
                <Col md={6} className="pe-md-4">
                    <PaymentInfo pageVm={viewModel} model={model} onChange={handleChange} handleSubmit={handleSubmit} isFormSubmitting={isFormSubmitting} showPayNowButtons={showPayNowButtons} />
                    <ReservationDetails jobs={viewModel.jobs} payNow={model.payNow} pageVm={viewModel} />
                </Col>
                <Col md={6} className="ps-md-4">
                    <JobList jobs={viewModel.jobs} jobDetailsUrl={viewModel.jobDetailsUrl} model={model} onChange={handleChange} />
                    {viewModel.eligibleForPorchHomeAssistantOffer && <PorchHomeAssistant />}
                </Col>
            </Row>
            <DevOnlyWrapper><button type='button' onClick={() => enablePayNow()}>DEV: Force Showing Pay Now</button></DevOnlyWrapper>
        </div>
    );
};
