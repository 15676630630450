import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelpTypeNeededEnum, JobDetailsTypeOfHelpEnum, JobDetailsTypeOfHelpEnumDescription, TruckSizeRangesDescription } from '@hah/enums';
import * as iconLibrary from '@hah/icons';
import { memo } from 'react';
import { CtaFormErrors, CtaFormValues } from '../modules/ctaFormSchema';
import { HahDatePicker } from './Forms/HahDatePicker';
import { HahInput } from './Forms/HahInput';

const truckOptions = [] as unknown as [{ label: string; value: number }];
const typeOfHelpOptions = [] as unknown as [{ label: string; value: number }];
typeOfHelpOptions.push(...JobDetailsTypeOfHelpEnumDescription.all.filter((x) => x.value !== 0 && x.value !== 4));
truckOptions.push(...TruckSizeRangesDescription.all);
export interface CtaFormStackedProps {
    typeOfHelp: JobDetailsTypeOfHelpEnum;
    helpTypeNeeded?: HelpTypeNeededEnum;
    title: string;
    values: CtaFormValues;
    errors: CtaFormErrors;
    onFieldChange: (fieldName: keyof CtaFormValues, fieldValue: any) => void;
    handleChangeHelpTypeNeeded: (value: HelpTypeNeededEnum | undefined) => void;
}

export const CtaFormStacked = memo((props: CtaFormStackedProps) => {
    const mptImg = '/_static/img/public/react/cta-mpt.png?v=20230525';
    const laborImg = '/_static/img/public/react/cta-labor.png?v=20230525';
    const shouldAllowSwitchingToMpt = window.shouldShowMpt ?? true;
    // If shouldShowMpt is true, then we need to check if the helpTypeNeeded is MoversPlusTruck, otherwise we should be setting this to false to hide the MoversPlusTruck option for affiliates
    const isMoversPlusTruck = shouldAllowSwitchingToMpt ? props.helpTypeNeeded == HelpTypeNeededEnum.MoversPlusTruck : false;

    const hideLoading = props.values.typeOfHelp === JobDetailsTypeOfHelpEnum.Unloading && !isMoversPlusTruck ? 'd-none' : '';
    const hideUnloading = props.values.typeOfHelp === JobDetailsTypeOfHelpEnum.Loading && !isMoversPlusTruck ? 'd-none' : '';

    return (
        <>
            <div className="cta-card-container border rounded bg-white px-3 py-3 mx-auto">
                <div className="row pb-2 pb-lg-0">
                    <div className="col-12 col-lg-3 d-none d-lg-block">
                        {shouldAllowSwitchingToMpt && (
                            <button type="button" className="btn btn-light rounded-pill border mb-3 py-1" onClick={() => props.handleChangeHelpTypeNeeded(undefined)}>
                                <FontAwesomeIcon icon={iconLibrary.faArrowLeft} className="text-notification-dark-10 pe-1 pw-goBackButton" /> Go Back
                            </button>
                        )}
                    </div>
                    <div className="col-12 col-lg-9">
                        <h4 className="text-notification-dark-10 text-center">{props.title}</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-auto d-none d-lg-block">
                        <div className="cta-card px-2 px-md-4 border border-notification-dark-10 rounded h-100 position-relative">
                            <FontAwesomeIcon icon={iconLibrary.faCheckCircle} className="text-notification-dark-10 fs-4 position-absolute top-0 end-0 mt-2 me-2" />
                            <div className="row h-100 d-flex align-items-end py-3">
                                <div className="col text-center">
                                    <img src={isMoversPlusTruck ? mptImg : laborImg} height="145" width="145" alt={isMoversPlusTruck ? 'Movers Loading boxes onto a rental truck' : 'Movers loading and unloading furniture from a house while homeowner relaxes on a chair'}></img>
                                    <br />
                                    <div className="my-3">
                                        <strong className="pw-selectedTypeOfHelpLabel">{isMoversPlusTruck ? 'Movers + Truck' : 'Movers Only'}</strong>
                                    </div>
                                    <p className="text-gray mt-3 mb-2">{isMoversPlusTruck ? 'I need movers and transportation' : 'Movers to help load/unload'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg">
                        <div className={`row align-items-center mb-2 ${isMoversPlusTruck ? 'mpt-label-row' : ''}`}>
                            <div className="mb-2 fw-bold col-auto">{isMoversPlusTruck ? 'What size truck do you need?' : 'What service do you need?'}</div>
                            {isMoversPlusTruck && (
                                <div className="col-sm text-md-end">
                                    <button
                                        type="button"
                                        className="btn btn-link btn-sm js-modal-truck-and-labor p-0"
                                        onClick={() => Hireahelper.Global.Bootstrap.openModalWithUrl('/checkoutv2/modaltruckandlaborguide/?selected=2&pc=3', 'Moving Guides', null, true, true)}
                                        data-modalid="modaltruckandlaborguide"
                                        data-taboraccordion="true"
                                    >
                                        Moving Truck Dimensions
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className={`row pb-2 align-items-center mb-2 typeofhelp-column gx-2 gx-md-3 ${isMoversPlusTruck ? 'mpt-trucksize-row' : ''}`}>
                            {isMoversPlusTruck ? (
                                <>
                                    {truckOptions.map((x) => (
                                        <div key={x.value} className="col-6 col-md mb-2 mb-lg-0">
                                            <button type="button" onClick={() => props.onFieldChange('truckSizeRange', x.value)} className={`form-check-btn btn btn-outline-base rounded-pill w-100 ${props.values.truckSizeRange == x.value ? 'active' : ''}`}>
                                                {x.label}
                                            </button>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {typeOfHelpOptions.map((x) => (
                                        <div key={x.value} className="col-auto mb-2 mb-lg-0">
                                            <button type="button" onClick={() => props.onFieldChange('typeOfHelp', x.value)} className={`form-check-btn btn btn-outline-base rounded-pill w-100 px-2 px-sm-3 ${props.values.typeOfHelp == x.value ? 'active' : ''}`}>
                                                {x.label}
                                            </button>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        <div className="row">
                            <div className={`col-12 col-md order-md-first ${hideLoading}`}>
                                <HahInput
                                    autoComplete="postal-code"
                                    labelText="Loading ZIP Code"
                                    fieldType="text"
                                    fieldName="loadingZip"
                                    fieldClass="form-control"
                                    fieldIcon={iconLibrary.faTruckLoading}
                                    fieldValue={props.values['loadingZip']}
                                    errorMessage={props.errors['loadingZip']}
                                    onFieldChange={props.onFieldChange}
                                />
                            </div>
                            {props.values.typeOfHelp === JobDetailsTypeOfHelpEnum.LoadingAndUnloading && (
                                <div className={'col-auto g-0 d-none d-md-block order-md-0'}>
                                    <div className="cta-form-arrow d-flex align-items-center">
                                        <FontAwesomeIcon icon={iconLibrary.faArrowRight} className="fs-4" style={{ opacity: '0.15' }} />
                                    </div>
                                </div>
                            )}
                            <div className={`col-12 ${isMoversPlusTruck ? 'col-md-6 pe-md-4 order-3' : 'col-md order-md-3'} ${hideLoading}`}>
                                <HahDatePicker
                                    labelText={`${isMoversPlusTruck ? 'Move' : 'Loading'} Date`}
                                    fieldType="text"
                                    fieldName="loadingDate"
                                    fieldClass="form-control"
                                    fieldIcon={iconLibrary.faCalendar}
                                    fieldValue={props.values['loadingDate']}
                                    errorMessage={props.errors['loadingDate']}
                                    onFieldChange={props.onFieldChange}
                                />
                            </div>
                            <div className={'col-12 order-md-2'}></div>
                            <div className={`col-12 col-md order-md-1 ${hideUnloading}`}>
                                <HahInput
                                    autoComplete="postal-code"
                                    labelText="Unloading ZIP Code"
                                    fieldType="text"
                                    fieldName="unloadingZip"
                                    fieldClass="form-control"
                                    fieldIcon={iconLibrary.faTruckLoading}
                                    fieldIconClass="fa-flip-horizontal"
                                    fieldValue={props.values['unloadingZip']}
                                    errorMessage={props.errors['unloadingZip']}
                                    onFieldChange={props.onFieldChange}
                                />
                            </div>
                            {!isMoversPlusTruck && (
                                <>
                                    {props.values.typeOfHelp === JobDetailsTypeOfHelpEnum.LoadingAndUnloading && (
                                        <div className={'col-auto g-0 d-none d-md-block order-md-4'}>
                                            <div className="cta-form-arrow d-flex align-items-center">
                                                <FontAwesomeIcon icon={iconLibrary.faArrowRight} className="fs-4" style={{ opacity: '0.15' }} />
                                            </div>
                                        </div>
                                    )}
                                    <div className={`col-12 col-md order-md-last ${hideUnloading}`}>
                                        <HahDatePicker
                                            labelText="Unloading Date"
                                            fieldType="text"
                                            fieldName="unloadingDate"
                                            fieldClass="form-control"
                                            fieldIcon={iconLibrary.faCalendar}
                                            fieldValue={props.values['unloadingDate']}
                                            errorMessage={props.errors['unloadingDate']}
                                            onFieldChange={props.onFieldChange}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-notification-dark-10 btn-lg rounded-pill fw-semibold fs-6 w-100 pw-compareMoverPricesBtn" type="submit">
                                    Compare Mover Prices
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isMoversPlusTruck && (
                <div className="text-center mt-4">
                    <a className="text-cool-grey-800 fw-semibold fs-5 js-sitePhoneNum" href="tel:1-866-517-6369">
                        <FontAwesomeIcon icon={iconLibrary.farQuestionCircle} className="me-3" />
                        Need some help? <br className="d-sm-none" />
                        Call <span className="js-sitePhoneNumOnly">(866) 517-6369</span>
                    </a>
                </div>
            )}
        </>
    );
});
