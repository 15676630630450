/* eslint-disable react/jsx-no-target-blank */
import { AddressType, PaymentMethod } from '@hah/enums';
import * as api from '@hah/typewriter/controllers/CheckoutController';
import { OnChangePickSetState } from '@hah/utils';
import * as Sentry from '@sentry/browser';
import { Formik, FormikHelpers } from 'formik';
import React, { MutableRefObject } from 'react';
import { Card, Col, Form, OverlayTrigger, Popover, Row, Spinner } from 'react-bootstrap';
import { BookOrderValidationModel, bookOrderValidationSchema } from '../validationSchema';
import { ErrorFocus } from './HahFormikField';
import { PaymentMethodBtDropInContainer } from './PaymentMethodBtDropIn';
import { Dropin } from '../../custom-types/braintree-web-drop-in-custom';

type Props = {
    pageVm: models.CheckoutPaymentStepReactViewModel;
    model: models.CheckoutPaymentViewModel;
    onChange: OnChangePickSetState<models.CheckoutPaymentViewModel>;
    handleSubmit: (values: BookOrderValidationModel, helpers: FormikHelpers<BookOrderValidationModel>, submitModel: models.CheckoutPaymentViewModel, paymentNonce: MutableRefObject<string>, dropinInstance: MutableRefObject<Dropin | undefined>) => Promise<void>;
    isFormSubmitting: boolean;
    showPayNowButtons: boolean;
};
export const emptyAddress: models.AddressModel = { street: '', streetLineTwo: '', city: '', state: '', zip: '', type: AddressType.Unknown };
const initialValues = {
    paymentInfo: {
        billingAddress: emptyAddress,
    },
    billingContactSameAsJobContact: true,
    billingContact: { firstName: '', lastName: '', email: '', primaryPhone: '', alternatePhone: '' },
    paymentMethod: PaymentMethod.Unknown,
} as BookOrderValidationModel;

export const PaymentInfo = ({ onChange, model, pageVm, handleSubmit, isFormSubmitting, showPayNowButtons }: Props) => {
    const [btToken, setBtToken] = React.useState<models.BrainTreeTokenResult | undefined>(undefined);
    React.useEffect(() => {
        let ignore = false;
        const fetchResult = async () => {
            try {
                const response = await api.getBtClientToken();
                if (!ignore) {
                    setBtToken(response);
                }
            } catch (error: any) {
                Sentry?.captureException(error, {
                    tags: {
                        customerAuthKey: pageVm.authToken.customerAuthKey,
                        linkedJobsKey: pageVm.authToken.linkedJobsKey,
                        'grouping.Checkout': true,
                    },
                    extra: { model: JSON.stringify(model) },
                });
                globalSitewide.toastError('BrainTree Client Token Error', 'There was an issue with our payment processor. Please try again or contact support if the issue persists.');
            }
        };
        fetchResult();

        return () => {
            ignore = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tcpaContent = pageVm.tcpaShortContent
        .replace('[LearnMoreLink]', '')
        .trim()
        .replace(/^<p>(.*)<\/p>$/, '$1');
    const tcpaLongContent = pageVm.tcpaLongContent;

    const tcpaOverlay = (
        <Popover className="p-2">
            <div dangerouslySetInnerHTML={{ __html: tcpaLongContent }} />
        </Popover>
    );

    const paymentNonce = React.useRef<string>('');
    const dropinInstance = React.useRef<Dropin | undefined>(undefined);

    return (
        <div className="mt-2">
            <Row>
                <Col>
                    <h4>
                        Payment Info <span className="text-danger">*</span>
                    </h4>
                </Col>
                <Col xs="auto">
                    <div className="logo-wrap">
                        <a href="https://www.braintreegateway.com/merchants/z8jmsswyz6whb5qr/verified" target="_blank" rel="noreferrer noopener">
                            <img className="" src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png" alt="BrainTree Badge" width="132" data-pin-nopin="true" />
                        </a>
                    </div>
                </Col>
            </Row>
            <Card className="mt-2 shadow p-2 mb-4 bg-white rounded border-notification-dark-10">
                <Card.Body>
                    {showPayNowButtons && <PayNowButtons model={model} onChange={onChange} grandTotal={pageVm.grandTotalFormatted} payNowGrandTotal={pageVm.payNowGrandTotalFormatted} />}
                    <Formik initialValues={initialValues} validationSchema={bookOrderValidationSchema} onSubmit={(v, h) => handleSubmit(v, h, model, paymentNonce, dropinInstance)}>
                        <Form noValidate>
                            <ErrorFocus />
                            {!btToken && <Spinner />}
                            {btToken && (
                                <PaymentMethodBtDropInContainer
                                    onChange={onChange}
                                    model={model}
                                    pageVm={pageVm}
                                    isFormSubmitting={isFormSubmitting}
                                    btToken={btToken.clientToken}
                                    googleMerchantID={btToken.googleMerchantID}
                                    paymentNonce={paymentNonce}
                                    dropinInstance={dropinInstance}
                                />
                            )}
                        </Form>
                    </Formik>
                </Card.Body>
            </Card>
            {pageVm.eligibleForPorchHomeAssistantOffer && (
                <div>
                    <div className="small text-muted d-inline" dangerouslySetInnerHTML={{ __html: tcpaContent }} />
                    <OverlayTrigger trigger="click" rootClose placement="top" overlay={tcpaOverlay}>
                        <span className="small text-muted pe-auto text-decoration-underline" role="button">
                            Learn More
                        </span>
                    </OverlayTrigger>
                </div>
            )}
        </div>
    );
};

const PayNowButtons = ({ onChange, model, grandTotal, payNowGrandTotal }: { onChange: OnChangePickSetState<models.CheckoutPaymentViewModel>; model: models.CheckoutPaymentViewModel; grandTotal: string; payNowGrandTotal: string }) => {
    const handleChangePayNow = (checked: boolean) => {
        onChange({ payNow: checked });
    };
    return (
        <>
            <div className="row align-items-baseline">
                <div className="col">
                    <div className={`border-top border-start border-end heavy-items-card w-100 bg-white p-3 text-start rounded-top ${model.payNow ? 'active border-bottom' : ''}`} id="heavyItemsCardYes" onClick={() => handleChangePayNow(true)}>
                        <div className="align-items-center text-body gx-3 row">
                            <div className="col-auto">
                                <input type="radio" className="form-check-input" name="btnradio" id="btnradio1" autoComplete="off" onChange={() => handleChangePayNow(true)} checked={model.payNow} />
                            </div>
                            <div className="col">
                                <h3 className="fw-semibold d-inline-block fs-6 mb-0 mt-1">
                                    <span className="me-2">{payNowGrandTotal}</span> Pay now and save
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className={`border-bottom border-start border-end heavy-items-card w-100 mb-3 bg-white p-3 text-start rounded-bottom ${model.payNow ? '' : 'active border-top'}`} id="heavyItemsCardNo" onClick={() => handleChangePayNow(false)}>
                        <div className="align-items-center text-body gx-3 row">
                            <div className="col-auto">
                                <input type="radio" className="form-check-input" name="btnradio" id="btnradio2" autoComplete="off" onChange={() => handleChangePayNow(false)} checked={!model.payNow} />
                            </div>
                            <div className="col">
                                <h3 className="fw-semibold d-inline-block fs-6 mb-0 mt-1">
                                    <span className="me-2">{grandTotal}</span> Pay on move day
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {model.payNow && <div className="small my-2">You will be charged after choosing a payment method below.</div>}
        </>
    );
};
