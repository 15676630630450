﻿import * as enums from '@hah/enums';





import { executePost } from '../http-utils';




    export const endpointUrl = '/hahpi/public/checkout/';
    


    
    export const checkout = (model: models.CheckoutPaymentViewModel) => executePost<models.HahApiResult<models.CheckoutResultViewModel>>(endpointUrl + 'checkout/', model);
    
    export const getBtClientToken = () => executePost<models.BrainTreeTokenResult>(endpointUrl + 'getBtClientToken/');
    

    

