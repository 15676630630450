import * as iconLibrary from '@hah/icons';
import { HelpTooltip, Icon } from '@hah/shared';
import { formatCurrency } from '@hah/utils';
import * as React from 'react';
import { Card, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';

type Props = {
    jobs: readonly models.CheckoutPaymentStepJobViewModel[];
    payNow: boolean;
    pageVm: models.CheckoutPaymentStepReactViewModel;
};

type DiscountComponentProps = {
    title: string;
    subtotal: string;
    discountAmount: number | string;
}

function typeOfHelpString(job: models.CheckoutPaymentStepJobViewModel) {
    const toh = job.typeOfHelp;

    if (toh.loadingHelp && toh.unloadingHelp) {
        return 'Load/Unload';
    } else if (toh.loadingHelp) {
        return 'Load';
    } else if (toh.unloadingHelp) {
        return 'Unload';
    } else if (toh.moveOnSiteOnly) {
        return 'Move On-Site';
    } else {
        return 'Moving Labor';
    }
}

const JobIcon = (job: models.CheckoutPaymentStepJobViewModel) => {
    const toh = job.typeOfHelp;

    if (toh.loadingHelp && toh.unloadingHelp) {
        return <Icon className="me-3 job-icon" fixedWidth icon={iconLibrary.faTruck} />;
    } else if (toh.loadingHelp) {
        return <Icon className="me-3 fa-solid job-icon" fixedWidth icon={iconLibrary.faDolly} />;
    } else if (toh.unloadingHelp) {
        return <Icon className="fa-flip-horizontal me-3 job-icon" fixedWidth icon={iconLibrary.faDolly} />;
    } else {
        return <Icon className="me-3 job-icon" fixedWidth icon={iconLibrary.faPersonDolly} />;
    }
};
const DiscountComponent = ({title, subtotal, discountAmount}: DiscountComponentProps) => (
    <>
        <Row className="mt-3">
            <Col className="fw-bold">Subtotal</Col>
            <Col xs="auto" className="text-end fw-bold">
                {subtotal}
            </Col>
        </Row>
        <Row className="mt-1">
            <Col className="text-notification">{title}</Col>
            <Col xs="auto" className="text-notification text-end fw-bold">
                -{discountAmount}
            </Col>
        </Row>
    </>
);

export const ReservationDetails = ({ jobs, pageVm, payNow }: Props) => {

    const isMultiOrder = jobs.length > 1;
    const chargeNotice = React.useMemo(() => {
        if (pageVm.anySameDay || pageVm.anyNextDay) {
            return 'Your card will be charged today to secure your reservation.';
        } else if (isMultiOrder) {
            return 'You will not be charged until the day before each move. You will be charged individually for the amount of each specific service.';
        } else {
            return 'You will not be charged until the day before the move.';
        }
    }, [pageVm.anySameDay, pageVm.anyNextDay, isMultiOrder]);

    /* TODO: This should pull from a TCPA markdown */
    const termsOverlay = (
        <Popover className="p-2">
            <div>
                <strong>Terms of Service</strong>
            </div>
            <div className="mt-2">We collect your billing info to secure your reservation. You will not be charged until the day before the move.</div>
            <div className="mt-2">Your service provider will not receive your payment until you tell us they completed your order via toll-free number or automated text.</div>
            <div className="mt-2">
                By clicking the &quot;Book now&quot; payment button, you agree to our{' '}
                <a href="https://www.porchmovinggroup.com/legal/" target="_blank" rel="noreferrer">
                    Terms of Service
                </a>
                .
            </div>
        </Popover>
    );



    return (
        <div className="mt-3">
            <h4>Reservation Details</h4>
            <Card className="mt-2 shadow p-2 mb-4 bg-white rounded">
                <Card.Body>
                    {jobs.map((job) => (
                        <div className="mt-2" key={`checkout-job-reservation-${job.jobID}`}>
                            <Row>
                                <Col>
                                    <JobIcon {...job} />
                                    {typeOfHelpString(job)}, {job.numHelpers} crew x {job.hours} hrs
                                </Col>
                                <Col xs="auto" className="text-end">
                                    {
                                        // FUTURE SG: let's discuss if we can show the total without the service charge
                                        // {formatCurrency(job.grandTotal - job.whatsIncluded.serviceCharge)}
                                    }
                                    {formatCurrency(job.subtotalBeforeDiscount)}
                                </Col>
                            </Row>
                        </div>
                    ))}
                    <hr />

                    <Row className="mt-3">
                        <Col>
                            <span>Service fee</span>
                            <HelpTooltip className="ms-2" icon="info">
                                This helps us run our platform and offer services like damage coverage and support on your move.
                            </HelpTooltip>
                        </Col>
                        <Col xs="auto" className="text-end text-notification">
                            Included
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>
                            <span>Service fee</span>{' '}
                            <HelpTooltip className="ms-2" icon="info">
                                This helps us run our platform and offer services like damage coverage and support on your move.
                            </HelpTooltip>
                        </Col>
                        <Col xs="auto" className="text-end">
                            {formatCurrency(jobs.sum((x) => x.whatsIncluded.serviceCharge))}
                        </Col>
                    </Row>
                    {jobs.length > 1 &&
                        jobs.map((job) => (
                            <Row className="mt-3" key={`checkout-job-reservation-fee-${job.jobID}`}>
                                <Col>
                                    <span>{typeOfHelpString(job)} service fee</span>
                                </Col>
                                <Col xs="auto" className="text-end">
                                    {formatCurrency(job.whatsIncluded.serviceCharge)}
                                </Col>
                            </Row>
                        ))} */}
                    <Row className="mt-3">
                        <Col>
                            <Icon className="text-notification me-3" fixedWidth icon={iconLibrary.faCheck} />
                            <span>24/7 Customer service</span>
                        </Col>
                        <Col xs="auto" className="text-end text-notification">
                            Included
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Icon className="text-notification me-3" fixedWidth icon={iconLibrary.faCheck} />
                            <span>Service guarantee</span>
                        </Col>
                        <Col xs="auto" className="text-end text-notification">
                            Included
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Icon className="text-notification me-3" fixedWidth icon={iconLibrary.faCheck} />
                            <span>Verified movers</span>
                        </Col>
                        <Col xs="auto" className="text-end text-notification">
                            Included
                        </Col>
                    </Row>
                    <hr />

                    {payNow && (
                        <DiscountComponent  title="Pay now and save" discountAmount={pageVm.payNowDiscountPriceFormatted} subtotal={pageVm.grandTotalFormatted} />
                    )}


                    {(!payNow && pageVm.discountAmount > 0) && (
                        <DiscountComponent title="Discount" discountAmount={pageVm.discountAmountFormatted} subtotal={pageVm.subtotalBeforeDiscountFormatted} />
                    )}

                    <Row className="mt-3">
                        <Col className="fw-bold">Total</Col>
                        <Col xs="auto" className="text-end fw-bold">
                            {payNow ? pageVm.payNowGrandTotalFormatted : pageVm.grandTotalFormatted}
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col className="text-notification h5">Due now</Col>
                        <Col xs="auto" className="text-end text-notification h5">
                            {payNow ? pageVm.payNowGrandTotalFormatted : pageVm.balanceDueNowFormatted}
                        </Col>
                    </Row>
                    {!payNow && <small className="text-muted charge-notice">{chargeNotice}</small>}
                    <div className="mt-3">
                        Terms of Service
                        <OverlayTrigger trigger="click" rootClose placement="top" overlay={termsOverlay}>
                            <span className="ms-2 text-dark  text-decoration-underline" role="button">
                                <Icon icon={iconLibrary.faInfoCircle} />
                            </span>
                        </OverlayTrigger>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};
