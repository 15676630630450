import { CancellationFeePolicy } from '@hah/enums';
import { formatCurrency } from '@hah/utils';
import { Col, Row } from 'react-bootstrap';

// FUTURE: after the test we can remove control case statements and just return what it should be
export function CancellationFeesList({ cancellationFees, earlyCancellationPenalty, earlyCancellationPenaltyLessThanOneHourNotice }: {
    cancellationFees: readonly models.CancellationFeeBreakpoint[];
    earlyCancellationPenalty: number;
    earlyCancellationPenaltyLessThanOneHourNotice: number;
}) {

    const isVariant = cancellationFees.some(x => [
        CancellationFeePolicy.VariantA,
        CancellationFeePolicy.VariantB,
        CancellationFeePolicy.VariantC
    ].includes(x.cancellationFeePolicy));

    const renderVariantComponents = () => (
        <>
            {cancellationFees.map((x) => {
                return <Row key={`${x.label} Cancellation Policy`}>
                    <Col xs={6} className="pt-2">
                        {x.cancellationFee > 0 ? `${x.maxHours} ${x.minHours === 0 ? 'hour' : 'hours'} or less:` : `More than ${x.minHours} hours notice:`}
                    </Col>
                    <Col xs={6} className="text-end pt-2">
                        {x.cancellationFee > 0 ? formatCurrency(x.cancellationFee) : 'free to cancel'}
                    </Col>
                </Row>
            })}
        </>
    );

    const renderDefaultComponent = () => (
        <div>
            <Row>
                <Col xs={6} className="ps-4 pt-2">
                    more than 24 hrs notice
                </Col>
                <Col xs={6} className="text-end pt-2">
                    free to cancel
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="ps-4 pt-2">
                    less than 24 hrs notice
                </Col>
                <Col xs={6} className="text-end pt-2">
                    {formatCurrency(earlyCancellationPenalty)}
                </Col>
            </Row>
            <Row>
                <Col xs={6} className="ps-4 pt-2">
                    less than 1 hr notice
                </Col>
                <Col xs={6} className="text-end pt-2">
                    {formatCurrency(earlyCancellationPenaltyLessThanOneHourNotice)}
                </Col>
            </Row>
        </div>
    );

    return (
        <div className="pt-3">
            <label className="fw-bold">Cancellation fees</label>
            {isVariant ? renderVariantComponents() : renderDefaultComponent()}
        </div>
    );
}
